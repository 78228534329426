import { apiClient } from './api-client';

export type GetPaymentIntentResponse = {
  intent: {
    paymentIntentSecret: string;
    stripePublishableKey: string;
  };
  status: number;
  apiResponseStatus: string;
  apiErrorCode: string;
};
type IntentPaymentArgs = {
  sessionId: string;
  documentId: string;
  search?: string;
};

export type SubmissionInfo = {
  status: 'EMAIL_SENT' | 'PAYMENT_REFUNDED' | 'PAYMENT_COMPLETED' | 'PAYMENT_INITIATED';
  intent: {
    paymentIntentSecret: string;
    stripePublishableKey: string;
  };
  downloadAvailableDays: number;
  downloadExpired: boolean;
};

export async function getPaymentIntent({ sessionId, documentId }: IntentPaymentArgs) {
  return await apiClient.post<GetPaymentIntentResponse>(
    `/api/${sessionId}/payment/${documentId}/prepare`,
  );
}

export async function getSubmissionInfo(documentId: string) {
  return await apiClient.get<SubmissionInfo>(`/api/submission/${documentId}/info `);
}
